import React, { Component } from 'react'
import { Link } from 'gatsby'

class Header extends Component {
  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    this.setState({ scrolled: window.scrollY > 0 })
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  render() {
    let { offCanvas, scrolled } = this.state
    let { main_menu } = this.props.data.wordpressAcfOptions.options

    let props = {
      activeClassName: 'active',
      onClick: this._hideOffCanvas,
    }

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <Link to='/' className={`logo ${scrolled ? 'logo--flip' : ''}`}>
              <svg className='logo__front' width="96px" height="96px" viewBox="0 0 96 96" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon className='color-logo' fill="#FFFFFF" points="0 96 96 96 96 0 0 0"></polygon>
                  <polygon className='color-background' fill="#2E4539" points="45 39.4400055 45 34 25 34 25 62 45 62 45 56.5599945 30.9345228 56.5599945 30.9345228 50.5198903 38.4354507 50.5198903 38.4354507 45.0798848 30.9345228 45.0798848 30.9345228 39.4400055"></polygon>
                  <path className='color-background' fill="#2E4539" d="M58.5867996,56.4606469 L58.6688801,56.4198449 L62.1919874,53.3650403 C67.3542606,48.8845954 70.3858624,46.3592449 70.3858624,41.960404 C70.3858624,37.1137129 66.6165138,33 60.3075574,33 C54.1622732,33 49.6967066,36.8693868 49.286793,43.0601142 L55.1863244,43.0601142 C55.6372782,40.1685175 57.1528348,38.5393531 60.0618046,38.5393531 C63.0934065,38.5393531 64.4452908,40.3312396 64.4452908,42.2455321 C64.4452908,44.3230323 63.2160386,45.4635445 57.3575474,50.5953637 L49,57.8454852 L49,62 L71,62 L71,56.4606469 L58.5867996,56.4606469 Z"></path>
                </g>
              </svg>
            </Link>
            <div className={`hamburger ${offCanvas ? 'active' : ''} ${scrolled ? 'scrolled' : ''}`} onClick={() => this.setState({ offCanvas: !offCanvas })}>
              <span className="lines"></span>
            </div>
          </div>
        </header>
        <nav className={`off-canvas ${offCanvas ? 'active' : ''}`}>
          <ul>
            { main_menu.map((el, i) => <li key={i}><Link to={el.link} {...props}>{ el.label }</Link></li>) }
          </ul>
        </nav>
      </>
    )
  }
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
