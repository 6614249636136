import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      fragment original on wordpress__wp_media {
        alt_text
        source_url
        localFile {
          childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
      {
        wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            main_menu {
              label
              link
            }
            address
            email
            phone
            instagram
            facebook
            youtube
            vimeo
            linkedin
            twitter
            pinterest
          }
        }
        posts: allWordpressPost(limit: 3) {
          edges {
            node {
              title
              slug
              acf {
                feature_image {
                  ...original
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Header location={location} data={data} />
          <main>
            { children }
          </main>
          <Footer data={data} />
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
