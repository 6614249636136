import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

import { Instagram, Facebook, Youtube, LinkedIn, Twitter, Pinterest, Vimeo } from './icons'

class Footer extends Component {

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  render() {
    let { options } = this.props.data.wordpressAcfOptions
    let { posts } = this.props.data

    return (
      <>
        <div className='footer__news'>
          <Fade bottom distance='40px' cascade={true}>
            <div className='footer__news__inner'>
              { posts.edges.map((edge, i) => (
                <Link to={`/${edge.node.slug}`} key={i}>
                  <picture>
                    <h4>{ edge.node.title }</h4>
                    { this.renderImage(edge.node.acf.feature_image) }
                  </picture>
                </Link>
              )) }
            </div>
          </Fade>
        </div>
        <div className='footer__contact'>
          <div className='footer__contact__inner'>
            <div><h3>Contact Us</h3></div>
            <div><a target='_blank' rel='nofollow noopener noreferrer' href={`//maps.google.com/?q=${options.address}`} dangerouslySetInnerHTML={{__html: options.address.split('\n').join('<br />') }} /></div>
            <div>
              T <a href={`tel:${ options.phone }`}>{ options.phone }</a><br />
              E <a href={`mailto:${ options.email }`}>{ options.email }</a>
            </div>
            <div><Link to='/contact'><span>Interested in a one hour<br /> design audit conversation?</span></Link></div>
          </div>
        </div>
        <footer className='footer'>
          <div className='footer__inner'>
            <div className='footer__logo'>
              <Link to='/'>
                <svg width="65px" height="42px" viewBox="0 0 65 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="none" strokeWidth="1" fill="#4B515B" fillRule="evenodd">
                    <polygon points="0 1 0 42 28 42 28 34.0340892 8.30850143 34.0340892 8.30850143 25.1901177 18.8088381 25.1901177 18.8088381 17.2242068 8.30850143 17.2242068 8.30850143 8.96591085 28 8.96591085 28 1"></polygon>
                    <path d="M47.5082453,33.9774257 L47.6233344,33.918168 L52.5882234,29.4939006 C59.8620313,23.0056436 64.1337094,19.348079 64.1337094,12.9774257 C64.1337094,5.95767311 58.8226609,0 49.9331453,0 C41.2738078,0 34.9813797,5.60395051 34.40415,14.570089 L42.716436,14.570089 C43.3516563,10.3819405 45.4874953,8.02257434 49.5860938,8.02257434 C53.8586641,8.02257434 55.7634328,10.6180595 55.7634328,13.3904059 C55.7634328,16.3988713 54.0317437,18.0507923 45.7765562,25.4826134 L34,35.9830692 L34,42 L65,42 L65,33.9774257 L47.5082453,33.9774257 Z"></path>
                  </g>
                </svg>
              </Link>
              <p>© 2019 . All rights reserved.</p>
            </div>
            <div className='footer__social'>
              { options.pinterest && <a href={ options.pinterest } target='_blank' rel='nofollow noopener noreferrer'><Pinterest color='#A6A8AB' /></a> }
              { options.twitter && <a href={ options.twitter } target='_blank' rel='nofollow noopener noreferrer'><Twitter color='#A6A8AB' /></a> }
              { options.linkedin && <a href={ options.linkedin } target='_blank' rel='nofollow noopener noreferrer'><LinkedIn color='#A6A8AB' /></a> }
              { options.youtube && <a href={ options.youtube } target='_blank' rel='nofollow noopener noreferrer'><Youtube color='#A6A8AB' /></a> }
              { options.vimeo && <a href={ options.vimeo } target='_blank' rel='nofollow noopener noreferrer'><Vimeo color='#A6A8AB' /></a> }
              { options.facebook && <a href={ options.facebook } target='_blank' rel='nofollow noopener noreferrer'><Facebook color='#A6A8AB' /></a> }
              { options.instagram && <a href={ options.instagram } target='_blank' rel='nofollow noopener noreferrer'><Instagram color='#A6A8AB' /></a> }
              <p>Design. <a href='//atollon.com.au' target='_blank' rel='noopener noreferrer'>Atollon</a></p>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
